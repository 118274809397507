








import {computed, defineComponent } from '@nuxtjs/composition-api';
import { usePageBuilder } from "~/composables/Palmers";

export default defineComponent({
  name: 'ElementHomeSlider',
  props: {
    element: {
      type: Object,
      required: true
    }
  },
  components: {
    HomeSlider: () => import("~/components/Palmers/Home/HomeSlider.vue")
  },
  setup(props) {
    const { getLink } = usePageBuilder();

    const videosData = computed(() => {
      const data = [];

      // Always push the first video element.
      data.push({
        source: props.element.source,
        preview: props.element.image_preview,
        video_url: props.element.video,
        title: props.element.title,
        subTitle: props.element.sub_title,
        buttonLink: getLink(props.element.link).url,
        buttonText: props.element.button_title,
        buttonLink2: getLink(props.element.link_2).url,
        buttonText2: props.element.button_title_2,
        buttonLink3: getLink(props.element.link_2_1).url,
        buttonText3: props.element.button_title_2_1
      });

      // Conditionally push the second video element if it exists.
      if (props.element.video_2 || props.element.image_preview_2 !== 'mgzbuilder/no_image.png' && props.element.image_preview_2 !== "") {
        data.push({
          source: props.element.source,
          preview: props.element.image_preview_2,
          video_url: props.element.video_2,
          title: props.element.title_2,
          subTitle: props.element.sub_title_2,
          buttonLink: getLink(props.element.link_3).url,
          buttonText: props.element.button_title_3,
          buttonLink2: getLink(props.element.link_4).url,
          buttonText2: props.element.button_title_4,
          buttonLink3: getLink(props.element.link_4_1).url,
          buttonText3: props.element.button_title_4_1
        });
      }

      // Conditionally push the third video element if it exists.
      if (props.element.video_3 || props.element.image_preview_3 !== 'mgzbuilder/no_image.png' && props.element.image_preview_3 !== "") {
        data.push({
          source: props.element.source,
          preview: props.element.image_preview_3,
          video_url: props.element.video_3,
          title: props.element.title_3,
          subTitle: props.element.sub_title_3,
          buttonLink: getLink(props.element.link_5).url,
          buttonText: props.element.button_title_5,
          buttonLink2: getLink(props.element.link_6).url,
          buttonText2: props.element.button_title_6,
          buttonLink3: getLink(props.element.link_6_1).url,
          buttonText3: props.element.button_title_6_1
        });
      }

      return data;
    });

    return {
      getLink,
      videosData
    }
  }
});
